#item-container {
	flex: 1;
	display: flex;
	flex-direction: column;
	margin-left: 300px;
}

#item-container a > svg {
	color: var(--color-primary);
	font-size: 50px;
	border: solid 2px;
	border-color: var(--color-primary);
	border-radius: 50%;
	margin: 12px;
	cursor: pointer;
	transition: all 0.3s;
}

#item-container a > svg:hover {
	background: var(--color-primary);
	color: var(--color-text-primary);
}

#item-container h1 {
	margin: 10px 20px;
	font-family: var(--font-family-primary);
	padding-bottom: 15px;
}

#item-container p {
	margin: 10px 20px;
	font-family: var(--font-family-primary);
	font-size: 20px;
}

#item-container p strong {
	font-family: var(--font-family-primary);
	color: var(--color-primary);
}

#item-container hr {
	margin: 24px 20px;
	background: var(--color-primary);
	height: 1px;
}

#item-container h2 {
	margin: 0px 20px;
	font-family: var(--font-family-primary);
}

#item-container ul {
	list-style: none;
}

#item-container ul > :first-child {
	margin-top: 20px;
	border-top: solid 1px;
	border-color: var(--color-primary);
}

#item-container ul li {
	padding: 20px;
	border-bottom: solid 1px;
	font-size: 20px;
	border-color: var(--color-primary);
}

#item-container footer {
	flex: 1;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

#item-container footer button {
	border: 0;
	border-radius: 8px;
	padding: 14px;
	width: 300px;
	font-size: 25px;
	margin: 20px;
	color: var(--color-text-primary);
	background: var(--color-primary);
	box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.75);
	transition: all 0.3s;
	cursor: pointer;
}

#item-container footer button:hover {
	background: var(--color-primary-lighter);
	box-shadow: none;
}

#item-container footer button div {
	color: var(--color-text-primary);
}
