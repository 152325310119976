:root {
	--color-primary: #0758bd;
	--color-primary-lighter: #9e9e9e;
	--color-secondary: #f9f9f9;
	--color-secondary-lighter: #dddddd;
	--color-info: #555555;
	--color-info-lighter: #f5f5f5;
	--color-text-primary: #ffffff;
	--font-family-primary: "Roboto";
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.root-container {
	display: flex;
}

.preload-image {
	display: none;
}
